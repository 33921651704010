class MyFooter extends HTMLElement {
    connectedCallback() {
        this.innerHTML = `
            <div id="footer">
                <span>ᐅ
                    <a href="./src/web-cotations/root.html">
                        Naviguer à travers l'arborescence des cotations
                    </a>
                </span>
                <span>ᐅ
                    <a href="/about.html">À Propos</a>
                </span>
                <div>
                    <br />
                    <small>mise à jour de janvier 2025, ccam v77</small>
                </div>
            </div>
        `;
    }
}

customElements.define('my-footer', MyFooter);